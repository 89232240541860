// @ts-nocheck
import {
  EditorReadyOptions,
  EditorType,
  ExportsFn,
} from '@wix/platform-editor-sdk';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';
import type {
  EditorScriptFlowAPI,
  FlowEditorSDK,
  WidgetBuilder,
} from '@wix/yoshi-flow-editor';
import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { createGallery } from './galleryService';
import { PRO_GALLERY } from './types/constants';

let editorType: any;
let instance: any;
let refresh: any;
let editorSubType: any;
let _editorSDK: FlowEditorSDK;
let _flowAPI: EditorScriptFlowAPI;

export async function editorReady(
  editorSDK: FlowEditorSDK,
  appDefId: string,
  options: EditorReadyOptions,
  flowAPI: EditorScriptFlowAPI,
) {
  _flowAPI = flowAPI;
  _editorSDK = editorSDK;
  editorType = options?.origin?.type;
  editorSubType = options?.origin?.subType;
  instance = options?.initialAppData?.instance;
  refresh = () => {
    editorSDK.document.application.livePreview.refresh();
    editorSDK.application.livePreview.refresh('', {
      shouldFetchData: true,
      source: 'AFTER_GALLERY_CREATED',
    });
  };
  editorSDK.addEventListener('widgetAdded', hadnleGalleryDuplicationEvent);
  if (
    flowAPI.experiments.enabled(
      'specs.pro-gallery.uninstallAppOnLastWidgetRemoved',
    )
  ) {
    editorSDK.addEventListener('componentDeleted', handleComponentDeleted);
  }

  self.pgBob = {};
  //  @ts-expect-error
  self.pgBob.instance = options.initialAppData.instance;
  //  @ts-expect-error
  self.pgBob.instanceId = options.initialAppData.instanceId;

  if (options?.firstInstall && options?.origin?.info?.type === 'APP_MARKET') {
    const shouldInstallBob = flowAPI.experiments.enabled(
      'specs.pro-gallery.shouldInstallBob',
    );
    if (shouldInstallBob) {
      installProGalleryBob({ editorSDK });
    } else {
      installProGalleryTPA({ editorSDK, appDefId });
    }
  }
}

const handleComponentDeleted = async (args: any) => {
  const compsByAppDefId = await _editorSDK.document.tpa.app.getAllComps(
    'token',
    PRO_GALLERY.PG_APP_DEFINITION_ID,
  );
  const galleryComps = compsByAppDefId.filter(
    (comp) => comp.type === 'TPAWidget',
  );
  // check if it is the last gallery widget
  if (galleryComps.length === 0) {
    try {
      // this removes the Pro Gallery app along with the Fullscreen page
      await _editorSDK.application.uninstall('token', {
        openConfirmation: false,
      });
    } catch (e) {
      console.error('Could not uninstall Pro Gallery app :', e);
    }
  }
};
const hadnleGalleryDuplicationEvent = (args: any) => {
  if (args?.detail.componentRef.type === 'DESKTOP') {
    const originCompId = args?.detail?.originalComponentId;
    const compId = args?.detail?.componentRef?.id;
    if (!compId || compId.includes('TPAMultiSection')) {
      return;
    }
    return createGallery({
      compId,
      originCompId,
      instance,
      refresh,
      editorType,
      editorSubType,
      httpClient: _flowAPI.httpClient,
      editorSDK: _editorSDK,
      t: _flowAPI.translations.t,
    });
  }
};

export const getAppManifest = async (
  options,
  editorSDK: FlowEditorSDK,
  contextParams,
  flowAPI: FlowAPI,
): Promise<AppManifest> => {
  const { t } = flowAPI.translations;
  const { appManifestBuilder } = options;
  if (
    flowAPI.experiments.enabled(
      'specs.pro-gallery.useNewOrganizeMediaCustomPanel',
    )
  ) {
    appManifestBuilder.configureWidget(
      '142bb34d-3439-576a-7118-683e690a1e0d',
      (widgetBuilder: WidgetBuilder) => {
        widgetBuilder.gfpp().set('mainAction2', {
          label: 'New Manage Media',
          onClick: (event: any) => {
            editorSDK.editor.openModalPanel('', {
              title: 'New Manage Media',
              url: getPanelUrl('WixProGallery', 'OrganizeMedia'),
              height: '90%' as any,
              width: '90%' as any,
              componentRef: event.detail.componentRef,
              centered: true,
            });
          },
        });
      },
    );
  }

  const baseManifest = await fetchWidgetsStageData(options);
  const manifest = appManifestBuilder.withJsonManifest(baseManifest).build();
  return manifest;
};

const installProGalleryTPA = ({ editorSDK, appDefId }) => {
  editorSDK.document.tpa.add.component('token', {
    managingAppDefId: appDefId,
    componentType: editorSDK.document.tpa.TPAComponentType.Widget,
    appDefinitionId: appDefId,
    widget: {
      widgetId: 'pro-gallery',
    },
  });
};

const installProGalleryBob = async ({ editorSDK }) => {
  console.log('BOBOBBBBB - INSTALL FROM APP MARKET');
  const presets = {
    Desktop: 'variants-l692sjpy',
    Mobile: 'variants-l692sjpy',
  };
  const addWidgetResult =
    await editorSDK.application.appStudioWidgets.addWidget('', {
      widgetId: '121a27a6-8fda-4a45-b2eb-60bf40299017',
      installationType: 'closed' as WidgetInstallationType,
      scopedPresets: {
        desktop: {
          layout: presets.Desktop,
          style: presets.Desktop,
        },
        mobile: {
          layout: presets.Mobile,
          style: presets.Mobile,
        },
      },
      layout: {
        height: 1000,
        width: 900,
      },
      layouts: {
        componentLayout: {
          id: '',
          hidden: false,
          height: {
            type: 'auto',
          },
          type: 'ComponentLayout',
          width: {
            type: 'percentage',
            value: 34,
          },
        },
        itemLayout: {
          id: '',
          alignSelf: 'center',
          margins: {
            left: {
              type: 'percentage',
              value: 15.999284485255783,
            },
            right: {
              type: 'percentage',
              value: 0,
            },
            top: {
              type: 'px',
              value: 81.99652862548828,
            },
            bottom: {
              type: 'px',
              value: 0,
            },
          },
        },
      },
    });
  const children = await editorSDK.components.getChildren('token', {
    componentRef: addWidgetResult,
  });
  const controllerRef = children[0];
  const compId = controllerRef.id;
  const galleryId = await createGallery({
    compId,
    instance,
    refresh,
    editorType,
    editorSubType,
    httpClient: _flowAPI.httpClient,
    editorSDK,
    t: _flowAPI.translations.t,
  });
  const config = { galleryId };

  await editorSDK.document.controllers.saveConfiguration('token', {
    controllerRef,
    config,
  });
};
